import hostOmni from "@/config/host";
import axios from "axios";

let webSocketProvider, TelefoniaConnection;

const bearerToken = JSON.parse(localStorage.getItem("login"))?.token;
const callbacks = {};

const start = async () => {
	if (!TelefoniaConnection) {
		webSocketProvider = await axios.get("/websocket-provider").then(response => response.data);
		switch (webSocketProvider) {
			default: break;
			case "SignalR":
				TelefoniaConnection = await import("@microsoft/signalr").then(signalR => {
					let connection = new signalR.HubConnectionBuilder()
						.withUrl(`${hostOmni}/TelefoniaHub`, { accessTokenFactory: () => bearerToken })
						.configureLogging(signalR.LogLevel.Error)
						.withAutomaticReconnect()
						.build();

					connection.serverTimeoutInMilliseconds = 180000;
					connection.keepAliveIntervalInMilliseconds = 60000;

					return connection;
				});

				break;
			case "SDWebSocket":
				TelefoniaConnection = await import("@/assets/lib/sd-websocket/SDWebSocket").then(sdWebSocket => {
					return new sdWebSocket.HubConnection()
						.withUrl(`${hostOmni.replace("https://", "wss://")}/TelefoniaHub`)
						.withAccessToken(bearerToken)
						.withAutomaticReconnect();
				});

				break;
		}
	}

	TelefoniaConnection.on("Log", callbacks["log"] ?? (() => { }));

	return await TelefoniaConnection.start();
}

const stop = () => {
	TelefoniaConnection.off("Log");
	return TelefoniaConnection.stop();
}

const onLog = callback => {
	callbacks.log = callback;
};

export default {
	TelefoniaConnection,
	start,
	stop,
	onLog
}