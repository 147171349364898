<template>
	<div id="monitoramento-screen">
		<section id="chathub-monitor">
			<h4>ChatHub Monitor</h4>

			<b>Usuários conectados</b>
		</section>
		<section id="telefoniahub-monitor">
			<h4>TelefoniaHub Monitor</h4>
			<b>Fluxo de log</b>
			<br />
			<input type="checkbox" id="telefoniahub-monitor-autoscroll" v-model="autoscroll" class="mr-2" />
			<label for="telefoniahub-monitor-autoscroll">Scroll automático</label>
			<br /><br />
			<div v-if="!isConnected" class="text-secondary">
				<b-spinner small class="mr-2"></b-spinner>
				Conectando...
			</div>
			<div v-else-if="!logs || logs.length == 0" class="text-secondary">
				<i class="fas fa-check mr-2"></i>
				Conectado. Nenhum log para exibir no momento.
			</div>
			<b-container v-else
						 id="telefoniahub-monitor-container"
						 ref="telefoniahub-monitor-container"
						 class="app-scroll-custom"
						 fluid>
				<b-row v-for="log in logs">
					<b-col cols="12" md="auto">
						<i v-if="log.type == 'error'" class="fas fa-times fa-fw text-danger"></i>
						<i v-else-if="log.type == 'info'" class="fas fa-info fa-fw text-info"></i>
						<i v-else class="fas fa-question fa-fw text-secondary"></i>
					</b-col>
					<b-col cols="12" md="auto">
						{{log.date}}
					</b-col>
					<b-col>
						{{log.message}}
					</b-col>
				</b-row>
			</b-container>
		</section>
	</div>
</template>

<script>
	import TelefoniaHub from "@/assets/js/hubs/TelefoniaHub";

	export default {
		components: {
		},
		data() {
			return {
				telefoniaHubConnection: null,
				logs: [],
				autoscroll: true,
				isConnected: false
			};
		},
		computed: {

		},
		watch: {

		},
		methods: {

		},
		mounted() {
			TelefoniaHub.onLog((type, date, message) => {
				this.logs.push({ type: type, date: date, message: message });
				if (this.autoscroll) this.$refs["telefoniahub-monitor-container"].scrollTo({ top: this.$refs["telefoniahub-monitor-container"].scrollHeight, behavior: "smooth" });
			});

			if (!TelefoniaHub.TelefoniaConnection || TelefoniaHub.TelefoniaConnection.state == "Disconnected") TelefoniaHub.start().then(() => {
				this.isConnected = true;
			});
		},
		destroyed() {
			TelefoniaHub.stop();
			this.isConnected = false;
		}
	};
</script>

<style scoped>
	#monitoramento-screen {
		padding: 20px;
	}

		#monitoramento-screen section {
			border-bottom: 1px #ccc solid;
			padding-bottom: 20px;
			margin-bottom: 40px;
		}

	#telefoniahub-monitor-container {
		max-height: 30vh;
		overflow-y: auto;
	}
</style>